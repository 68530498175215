import FlashLib from 'flashlib_onlyplay';
import { winFontStyle } from '../../fontStyles';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import WinAmountAnimated from '../../winAmountAnimated/winAmountAnimated';
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';
import StatesManager from 'Engine/base/states/StatesManager';
import eSoundType from '../../sounds/eSoundType';
import animationCreator from 'Engine/animations/animationCreator';
import animationTypes from '../../animations/animationTypes';
import { gsap } from 'gsap';
import SoundManager from 'Engine/soundManager/SoundManager';
import eEventTypes from 'Engine/enums/eBaseEventTypes';

export default class PopupWin extends FlashLib.MovieClip {
  constructor(data, $displayData) {
    super(data, $displayData);
    this.interactive = true;
    this.orintation = 'H'

    this.symbolSoundsType = {
      4: eSoundType.EST_SIMPLE_WIN_0,
      5: eSoundType.EST_SIMPLE_WIN_1,
      6: eSoundType.EST_SIMPLE_WIN_2,
      7: eSoundType.EST_SIMPLE_WIN_3,
      8: eSoundType.EST_SIMPLE_WIN_4
    }
    this.coefs = [
      { animationName: 'big_win_', coef: 15, frame: 1, sound: eSoundType.EST_BIG_WIN },
      { animationName: 'mega_win_', coef: 30, frame: 2, sound: eSoundType.EST_MEGA_WIN},
    ];

    this.animationCycleTime = 2000;
    this.animationStack = [];

    this.addListeners();
    this.init();
    this._initAnimationPromise = this.initAnimation();
  }

  init() {
    this.visible = false;
    this.overlay = this.getChildByName('overlay');
    this.wrapper = this.getChildByName('wrapper');
    this.panel = this.wrapper.getChildByName('panel');
    this.panel.visible = false;
    this.overlay.hide();
    this.smallWinContainer = this.wrapper.getChildByName('smallPanel');
    this.smallWinContainer.visible = false;
    this.smallWinAmount = this.smallWinContainer.getChildByName('amount');
    this.smallWinCounter = new WinAmountAnimated(this.smallWinAmount, winFontStyle)
    this.animationContainer = this.wrapper.getChildByName('animationContainer');

    this.setTextsStyle();
  }

  setTextsStyle() {
    this.smallWinAmount.style = { ...this.smallWinAmount.style,  ...winFontStyle}
  }

  addListeners() {
    GlobalDispatcher.add('stateEnd:stopWinState', this.complete, this);
    GlobalDispatcher.add('stateStart:showWinState', this.show, this);
    GlobalDispatcher.add('stateStart:stopWinState', this.stop, this);
    if (!window.OPWrapperService.config.skipBlocked) this.on('pointertap', () => {
      StatesManager.goToNextState();
    });
    window.OPWrapperService.eventManager.add(
          window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
          this.onGameResized,
          this
        );
  }

  onGameResized(data) {
    if (data.isLandscape) {
      this.wrapper.scale.set(this.wrapper.displayData.scaleX)
      this.wrapper.anchorDefault();
      if (this.winDataIndex === -1) {
        this.wrapper.y = this.wrapper.displayData.y - 76;
        this.wrapper.x = this.wrapper.displayData.x + 152;
      }
      this.animationContainer.scale.set(1);
      this.orintation = 'H'
    } else if (data.isMobile && data.isPortrait) {
      this.wrapper.centring();
      if (this.winDataIndex >= 0) {
        this.animationContainer.scale.set(0.76);
        this.wrapper.y = this.displayData.height / 2 - 630 - 80;
      } else {
        this.wrapper.y = this.displayData.height / 2 - 630 - 160;
      }
      this.wrapper.x = this.displayData.width / 2;
      this.wrapper.scale.set(0.8);
      this.orintation = 'V'
    }
  }

  async initAnimation() {
    this.animation = await animationCreator.createAnimationWithDelayedLoading(animationTypes.BIG_WIN);
    this.animation.visible = false;
    this.wrapper.getChildByName('animationContainer').addChild(this.animation);
  }
  stop() {
    if (this.animationStack.length) {
      this.animationStack = [];
    }
    this.executeAnimation()
    this.smallWinCounter.onStop = () => this.smallWinCounter.label.text = MoneyFormat.formatCurrency(EntryPoint.GameModel.totalWin, this.decimals, false);
    this.smallWinCounter.stop();
    clearTimeout(this.soundTimeout);
    SoundManager.stop(eSoundType.EST_MONEY_GAIN);
    SoundManager.play(eSoundType.EST_TILE, 0.35, false, 'winning');
  }

  complete() {
    this.hide();
    this.smallWinCounter.complete();
  }

  hide() {
    this.interactive = false;
    SoundManager.stop(eSoundType.EST_MONEY_GAIN)
    this.playMainBackgroundSound();
    gsap.to(this,  {alpha: 0, duration:0.4, onComplete: () =>{
        this.visible = false;
        if (this.animation) this.animation.visible = false;
        this.smallWinContainer.visible = false;
        this.animationStack = [];
        this.overlay.hide();
      }})
  }

  async show() {
    let linesLength = EntryPoint.GameModel.winnings.lines.length
    if (EntryPoint.GameModel.winnings.scatters) linesLength += EntryPoint.GameModel.winnings.scatters[0].count ** 1.8;
    let duration = 4000 * (linesLength);

    this.stopMainBackgroundSound()
    this.decimals = EntryPoint.GameModel.currencyInfo.decimals;

    this.winDataIndex = this.coefs.findIndex((item) => {
      return item.coef > EntryPoint.GameModel.totalWin / EntryPoint.GameModel.bet;
    });
    if (this.winDataIndex === -1) {
      this.winDataIndex = this.coefs.length - 1;
    } else {
      this.winDataIndex = this.winDataIndex - 1;
    }

    if (this.winDataIndex >=0) {
      if (!this.animation) {
        OPPreloader.showDelayedPreloader();
        await this._initAnimationPromise;
        OPPreloader.hideDelayedPreloader();
      }
      this.panel.visible = false;
      if (this.orintation === 'H') {
        this.wrapper.y = this.wrapper.displayData.y
        this.wrapper.x = this.wrapper.displayData.x
      } else if (this.orintation === 'V') {
        this.animationContainer.scale.set(0.76);
        this.wrapper.y = this.displayData.height / 2 - 630 - 80;
      }
      this.smallWinContainer.y = this.smallWinContainer.displayData.y
      this.animation.visible = true;
      this.animation.state.setAnimation(0,`${this.coefs[this.winDataIndex].animationName}idle`, true);
      this.overlay.show();
      GlobalDispatcher.dispatch(eEventTypes.EBET_BIG_WIN_ANIMATION_VISIBLE_CHANGE, true);
      duration += 2000 + 4000 * this.winDataIndex ;
    } else {
      this.panel.visible = true;
      if (this.orintation === 'H') {
        this.wrapper.y = this.wrapper.displayData.y - 76;
        this.wrapper.x = this.wrapper.displayData.x + 152;
      } else if (this.orintation === 'V') {
        this.wrapper.y = this.displayData.height / 2 - 630 - 160;
      }
    }

    this.createAnimationStack(this.winDataIndex);

    this.startAnimatedValue = 0;
    this.nextAnimatedValue = this.animationStack.length ? EntryPoint.GameModel.totalWin / (this.animationStack.length + 2) * 2 : EntryPoint.GameModel.totalWin;
    this.animationCycleTime = duration / (this.animationStack.length + 2) * 2 ;

    this.smallWinContainer.visible = true;
    this.smallWinCounter.animate(this.startAnimatedValue, this.nextAnimatedValue, this.decimals, this.animationCycleTime, 200000);
    this.smallWinCounter.onStop = this.showBigWinAnimation.bind(this);

    let soundAlias = eSoundType.EST_MONEY_GAIN;
    if (this.animationStack[this.animationStack.length - 1]) {
      soundAlias = this.animationStack[this.animationStack.length - 1].sound;
      SoundManager.play(soundAlias, 0.5, false, 'winning')
      const offset = 800;
      this.soundTimeout = setTimeout(
        () =>SoundManager.play(eSoundType.EST_MONEY_GAIN, 0.5, true, 'winning'),
        SoundManager.getSound(soundAlias).duration * 1000 - offset)
    } else {
      let symbolType;
      EntryPoint.GameModel.winnings.lines[0].path.forEach((place, reelIndex) => {
        if (EntryPoint.GameModel.reels[reelIndex][place - 1] !== 1) symbolType = EntryPoint.GameModel.reels[reelIndex][place - 1]
      });
      SoundManager.play(this.symbolSoundsType[symbolType], 0.5, false, 'winning')
      SoundManager.play(soundAlias, 0.5, true, 'winning')
    }

    this.visible = true;
    this.interactive = true;
    this.alpha = 1;
  }
  showBigWinAnimation() {
    this.smallWinCounter.onStop = this.executeAnimation.bind(this);
    this.startAnimatedValue = this.nextAnimatedValue;
    this.nextAnimatedValue = EntryPoint.GameModel.totalWin / (this.animationStack.length ) * 2;
    this.executeAnimation();
  }

  createAnimationStack(index) {
    this.coefs.forEach((el, i) => {
      if (i <= index) {
        const start = { name: el.animationName + 'start', type: 'start', sound: el.sound, coef: el.coef };
        const idle = { name: el.animationName + 'idle', type: 'idle', sound: el.sound, coef: el.coef };

        this.animationStack = [...this.animationStack, start, idle];
      }
    });
  }

  executeAnimation() {
    this.timeout && clearTimeout(this.timeout);
    if (!this.animationStack.length) {
      GlobalDispatcher.dispatch('bigWinContainer:stopState');
      return setTimeout(() => GlobalDispatcher.dispatch('bigWinContainer:nextState'), 1500);
    }

    const animation = this.animationStack.shift();

    if (animation.type === 'start') {
      this.animateWinning();
    }

    if (animation.type === 'idle') {
      this.startAnimatedValue = this.nextAnimatedValue;
      this.nextAnimatedValue = EntryPoint.GameModel.totalWin / this.animationStack.length * 2;
      this.setIdleTimeout();
    }

  }

  animateWinning() {
      this.smallWinCounter.animate(this.startAnimatedValue, this.nextAnimatedValue, this.decimals, this.animationCycleTime, 200000);
  }

  setIdleTimeout() {
      this.executeAnimation();
  }
  stopMainBackgroundSound() {
    const backgroundMainGameSound = SoundManager.getSound(eSoundType.EST_BACKGROUND);
    const tween  = gsap.timeline();
    tween
      .to(backgroundMainGameSound, { volume: 0, duration: 0.5})
      .call(() => {
        SoundManager.pause(eSoundType.EST_BACKGROUND)
      })
  }
  playMainBackgroundSound() {
    const backgroundMainGameSound = SoundManager.getSound(eSoundType.EST_BACKGROUND);
    const timeline  = gsap.timeline();
    timeline
      .call(() => {
        SoundManager.play(eSoundType.EST_BACKGROUND, 0.5, true, 'music');
      })
      .fromTo(backgroundMainGameSound, {volume: 0}, {volume: 0.5, duration: 1.5})
  }
}

